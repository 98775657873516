////////
////
//// Container
////
///////

.main {
  max-width: 128rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.wrapper-main {
  display: flex;
  position: relative;
  height: 100vh;
  align-items: center;
}

.container-images {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.bloc-image {
  position: absolute;
}

////////
////
//// Container Mobile
////
///////

@media only screen and (max-width: 767px) {
  .wrapper-main {
    margin: 15% 0;
  }

  h1 {
    display: flex;
    flex-direction: column;
    // justify-content: center;
  }
  a {
    margin: 15px 0;
  }

  .button {
    margin: 0;
  }

  .contact {
    margin-top: 0;
  }
}
