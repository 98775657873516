////////
////
//// Thumbnails
////
///////

canvas {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}

.project__content {
  margin-bottom: 3.2rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.project__content-image {
  overflow: hidden;
  border-radius: 4px;
}

.project__description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 0;
}

.project__text {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.project__category {
  margin: 0;
  display: inline-block;

  &.text-h3 {
    display: block;
    color: var(--color-grey-light);
  }
}

.project__image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: contain;
  transition: transform 300ms ease-in-out;
}

.project__image:hover {
  transform: scale(1.01);
}

.project__icon {
  width: 3.6rem;
  height: 3.6rem;
  padding: 0.6rem;
  position: absolute;
  right: 0;
  background-color: var(--color-grey-dark);
  color: var(--color-white);
  border-radius: 999rem;
  transition: all 250ms ease-in-out;
}

.project__content:hover .project__icon {
  background-color: var(--color-grey-medium);
  color: var(--color-white);
}

// Mobile

@media screen and (max-width: 768px) {
  .project__image {
    aspect-ratio: 3 / 2;
    object-fit: cover;
  }
}
