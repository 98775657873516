////////
////
//// Divider
////
///////

.divider__line {
  width: 100%;
  height: 1px;
  background-color: var(--color-grey-medium);
  margin: 8rem 0;
}

@media screen and (max-width: 768px) {
  .divider__line {
    margin: 4rem 0;
  }
}
