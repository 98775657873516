.social-container {
  display: flex;
  align-items: center;
}

a.icon {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 0.8rem;
  margin: 0.8rem;
  justify-content: center;
  align-items: center;
  background-color: var(--color-grey-dark);
  border-radius: 0.4rem;
  cursor: pointer;
  .img {
    display: flex;
    width: 32px;
    height: 32px;
  }
}

a:hover {
  background-color: var(--color-grey-medium);
}
