////////
////
//// HEADER
////
///////

.header {
  height: calc(100vh - 107px); // Soustrait la hauteur de la navigation
  position: relative; // Important pour le positionnement absolu du scroll

  .row {
    height: 100%;
    position: relative; // Pour maintenir le contexte de positionnement
  }

  &__title {
    display: flex;
    align-items: center; // Centre verticalement
    height: calc(100% - 107px); // Prend toute la hauteur disponible
  }

  &__scroll {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 3.2rem;
    left: 0;
  }
}
