////////
////
//// Skills
////
///////

.skills {
  .row {
    gap: 2.4rem;
  }

  .skill {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    margin-bottom: 3.2rem;

    &__icon {
      width: 8rem;
      height: 8rem;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .cell-start-0 .skill:last-child,
  .cell-start-6 .skill:last-child {
    margin-bottom: 3.2rem;
  }
}

// Responsive
@media screen and (max-width: 768px) {
  .skills {
    .row {
      [class*='cell-'] {
        grid-column: 1 / -1;
      }
    }

    .skill {
      margin-bottom: 2.4rem;

      &__icon {
        width: 6.4rem;
        height: 6.4rem;
      }
    }

    .cell-start-0 .skill:last-child,
    .cell-start-6 .skill:last-child {
      margin-bottom: 2.4rem;
    }
  }
}
