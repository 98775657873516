////////
////
//// Shapes
////
///////

.shapes-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: all;
}

.content-wrapper {
  position: relative;
  z-index: 1;
  pointer-events: none;

  nav,
  main,
  footer {
    pointer-events: auto;
  }
}
