////////
////
//// Colors
////
///////

:root {
  --color-black: #0c0c0c;
  --color-white: #ffffff;
  --color-grey-dark: #1d1d1d;
  --color-grey-medium: #292929;
  --color-grey-light: #888888;
}

.color-grey-light {
  color: var(--color-grey-light);
}

.color-white {
  color: var(--color-white);
}
