////////
////
//// About
////
///////

.about {
  .row {
    gap: 2.4rem;
  }
}

@media screen and (max-width: 768px) {
  .about {
    .row {
      [class*='cell-'] {
        grid-column: 1 / -1; // Force une seule colonne
      }
    }
  }
}
