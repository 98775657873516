////////
////
//// Grid
////
///////

:root {
  --grid-columns: 12;
  --grid-gutter: 4rem;
  --grid-spacer: 8rem;
}

.row {
  display: grid;
  max-width: 144rem;
  margin: auto;
  min-width: 0;
  width: 100%;
  padding-left: var(--grid-spacer);
  padding-right: var(--grid-spacer);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));
  grid-column-gap: 3.2rem;
}

.cell-start-0 {
  grid-column-start: 1;
}
.cell-end-0 {
  grid-column-end: 1;
}
.cell-0 {
  grid-column: span 0;
}
.cell-start-1 {
  grid-column-start: 2;
}
.cell-end-1 {
  grid-column-end: 2;
}
.cell-1 {
  grid-column: span 1;
}
.cell-2 {
  grid-column: span 2;
}
.cell-start-2 {
  grid-column-start: 3;
}
.cell-end-2 {
  grid-column-end: 3;
}
.cell-start-3 {
  grid-column-start: 4;
}
.cell-end-3 {
  grid-column-end: 4;
}
.cell-3 {
  grid-column: span 3;
}
.cell-start-4 {
  grid-column-start: 5;
}
.cell-end-4 {
  grid-column-end: 5;
}
.cell-4 {
  grid-column: span 4;
}
.cell-start-5 {
  grid-column-start: 6;
}
.cell-end-5 {
  grid-column-end: 6;
}
.cell-5 {
  grid-column: span 5;
}
.cell-start-6 {
  grid-column-start: 7;
}
.cell-end-6 {
  grid-column-end: 7;
}
.cell-6 {
  grid-column: span 6;
}
.cell-start-7 {
  grid-column-start: 8;
}
.cell-end-7 {
  grid-column-end: 8;
}
.cell-7 {
  grid-column: span 7;
}
.cell-start-8 {
  grid-column-start: 9;
}
.cell-end-8 {
  grid-column-end: 9;
}
.cell-8 {
  grid-column: span 8;
}
.cell-start-9 {
  grid-column-start: 10;
}
.cell-end-9 {
  grid-column-end: 10;
}
.cell-9 {
  grid-column: span 9;
}
.cell-start-10 {
  grid-column-start: 11;
}
.cell-end-10 {
  grid-column-end: 11;
}
.cell-10 {
  grid-column: span 10;
}
.cell-start-11 {
  grid-column-start: 12;
}
.cell-end-11 {
  grid-column-end: 12;
}
.cell-11 {
  grid-column: span 11;
}
.cell-start-12 {
  grid-column-start: 13;
}
.cell-end-12 {
  grid-column-end: 13;
}
.cell-12 {
  grid-column: span 12;
}
