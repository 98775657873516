////////
////
//// Typography
////
///////

.text-h1 {
  font-family: 'greed_condensed_trialsemibold';
  font-size: 4.6rem;
  font-style: normal;
  line-height: 5.6rem;
  font-feature-settings: 'liga' off, 'clig' off;
  color: var(--color-white);
}

.text-h2 {
  font-family: 'greed_condensed_trialsemibold';
  font-size: 4.8rem;
  line-height: 5.6rem;
  font-feature-settings: 'liga' off, 'clig' off;
  color: var(--color-white);
}

.text-h3 {
  font-family: 'neue_haas_unica_w1gmedium';
  font-size: 2rem;
  line-height: 150%; /* 30px */
}

.text-current {
  font-family: 'neue_haas_unica_w1gregular';
  font-size: 2rem;
  line-height: 150%; /* 30px */
}

.text-caption {
  font-family: 'neue_haas_unica_w1gmedium';
  font-size: 1.8rem;
  line-height: 150%;
  color: var(--color-white);
}

.text-button {
  font-family: 'neue_haas_unica_w1gmedium';
  font-size: 1.8rem;
  line-height: 150%;
  color: var(--color-white);
}

// Responsive
@media only screen and (max-width: 767px) {
  .text-h1 {
    font-size: 3.2rem;
    line-height: 4.2rem;
  }

  .text-h2-m {
    font-size: 1.8rem;
  }
}
