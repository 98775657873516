////////
////
//// Colors
////
///////

.button {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
  background: var(--color-grey-dark);
  color: var(--color-white);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.button:hover {
  background-color: var(--color-grey-medium);
}

.button__icon {
  margin-left: 0.8rem;
  width: 2rem;
  height: 100%;
}
