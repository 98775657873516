////////
////
//// BODY
////
///////

body {
  background-color: var(--color-primary);
  width: 100%;
  height: 100%;
}

////////
////
//// NAV
////
///////

.navigation {
  width: 100%;
  display: flex;
  height: 100%;
  position: relative;
  z-index: 2;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.4rem 0;
    width: 100%;
  }
}

.logo {
  display: flex;
  border-radius: 0.4rem;
  padding: 1.6rem;
  background: var(--color-grey-dark);
  color: var(--color-white);
  justify-content: center;
  align-items: center;
}

.logo:hover {
  background-color: var(--color-grey-medium);
}

.footer {
  margin-bottom: 3.2rem;
}

.credit {
  text-align: right;
}

.front {
  z-index: 99;
}

.contact {
  justify-content: right;
}

////////
////
//// Background
////
///////

.background--custom {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -2;
  top: 0;
  left: 0;
}
canvas#canvas {
  z-index: -1;
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

@media only screen and (max-width: 767px) {
}
